<template>
    <v-main>
      <PrescriptionsList/>
    </v-main>
</template>

<script>
import PrescriptionsList from '../components/OrderIntake/PrescriptionsList.vue';


export default ({

  components: { 
    PrescriptionsList,
  },

  data: () => ({      
  }),
});
</script>