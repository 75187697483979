<template v-slot:top>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col md="4"> Prescriptions </v-col>
                    <v-col offset-md="6" md="2">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details
                                      clearable
                                      @click:clear="searchCleared">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-btn color="secondary"
                   dark
                   class="ma-3"
                   @click="showDialogAddPrescription">
                Add
            </v-btn>
            <!--Add Prescription Dialog-->
            <v-dialog v-model="dialogAddPrescription" v-if="dialogAddPrescription"
                      @click:outside="closeAddPrescriptionDialog"
                      max-width="850px">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeAddPrescriptionDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        New Prescription
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="closeAddPrescriptionDialog">Close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <v-container>
                            <form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group v-model="prescriptionAdd.isRenewalPatient"
                                                       row
                                                       mandatory>
                                            <v-radio label="New Patient"
                                                     v-on:click="clearAddPrescriptionForm"
                                                     :checked="prescriptionAdd.isRenewalPatient"
                                                     v-bind:value="false"></v-radio>
                                            <v-radio default label="Renewal"
                                                     :checked="prescriptionAdd.isRenewalPatient"
                                                     v-bind:value="true"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-container v-if="prescriptionAdd.isRenewalPatient">
                                    <v-row>
                                        <v-col cols="12">
                                            <ordersPatientSearch v-on:patient-selected="searchPrescription" />
                                        </v-col>
                                    </v-row>
                                    <v-container v-show="showNoPatientRecord">
                                        <div class="text-h6">There is no existing prescription for this patient.</div>
                                    </v-container>
                                    <v-container v-show="showTooSoonToRenew">
                                        <div class="text-h6">It is too soon to renew this patient.</div>
                                    </v-container>
                                    <v-container v-show="showRenewPrescription">
                                        <v-row>
                                            <v-col cols="6">
                                                <div class="text-h6"><strong>Patient:</strong> {{ prescriptionAdd.patientFirstName }} {{ prescriptionAdd.patientLastName }}</div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="patientIdentifierType"
                                                          label="Patient Identifier Type"
                                                          :items="patientIdentifierItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6" v-if="patientIdentifierType == 'healthPlanMemberId'">
                                                <v-text-field v-model="prescriptionAdd.healthPlanMemberId"
                                                              label="Patient Health Plan Member ID">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6" v-if="patientIdentifierType == 'MRN'">
                                                <v-text-field v-model="prescriptionAdd.medicalRecordNumber"
                                                              label="Medical Record Number">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="sitesExist">
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showSiteDialog" v-if="prescriptionAdd.siteId == null">Add Site</v-btn>
                                                <v-btn color="secondary" @click="showSiteDialog" v-else>Change Site</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="site.name"
                                                              label="Site Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showProviderDialog" v-if="prescriptionAdd.providerId == null">Add Provider</v-btn>
                                                <v-btn color="secondary" @click="showProviderDialog" v-else>Change Provider</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="provider.name"
                                                              label="Provider Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu ref="prescriptionDateMenu"
                                                        v-model="prescriptionDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="prescriptionAdd.prescriptionDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="prescriptionDateFormatted"
                                                                      label="Prescription Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="prescriptionAdd.prescriptionDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.prescriptionDateMenu.save(prescriptionAdd.prescriptionDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="receivedDateMenu"
                                                        v-model="receivedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="prescriptionAdd.received"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="receivedDateFormatted"
                                                                      label="Received Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="prescriptionAdd.received"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.receivedDateMenu.save(prescriptionAdd.received)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.fileStoreObjectName"
                                                              prepend-icon="mdi-image"
                                                              label="Rx Google Drive URL link">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.legacyNumber"
                                                              label="Rx Number">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="prescriptionAdd.note"
                                                            outlined
                                                            label="Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="isPreQualified">
                                            <v-col cols="12">
                                                <v-textarea v-model="prescriptionAdd.shippingNote"
                                                            outlined
                                                            label="Shipping Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.onFulfillmentHold"
                                                          label="On Fulfillment Hold"
                                                          :items="booleanItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.initialDeliveryMethod"
                                                          label="Initial Delivery Method"
                                                          :items="deliveryMethodItems"
                                                          @changed="checkPreQualified"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.trainingTypeDescription"
                                                          label="Training Type Description"
                                                          :items="trainingTypeItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                                <!--New Patient-->
                                <v-container v-else>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select v-model="customerObject"
                                                      label="Customer"
                                                      :items="customerItems"
                                                      @change="customerSiteAndLineOfBusinessData(customerObject)"
                                                      item-text="name"
                                                      item-value="customerId"
                                                      return-object
                                                      outlined>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-container v-if="customerId">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.patientFirstName"
                                                              label="Patient First Name">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.patientLastName"
                                                              label="Patient Last Name">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.patientNickName"
                                                              label="Patient Nick Name">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.patientMiddleName"
                                                              label="Patient Middle Name">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="patientIdentifierType"
                                                          label="Patient Identifier Type"
                                                          :items="patientIdentifierItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6" v-if="patientIdentifierType == 'healthPlanMemberId'">
                                                <v-text-field v-model="prescriptionAdd.healthPlanMemberId"
                                                              label="Patient Health Plan Member ID">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6" v-if="patientIdentifierType == 'MRN'">
                                                <v-text-field v-model="prescriptionAdd.medicalRecordNumber"
                                                              label="Medical Record Number">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.residencePhone"
                                                              prepend-icon="mdi-phone"
                                                              v-mask="'###-###-####'"
                                                              :rules="$data.phoneNumberValidRules"
                                                              label="Residence Phone Number">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.cellPhone"
                                                              prepend-icon="mdi-phone"
                                                              v-mask="'###-###-####'"
                                                              :rules="$data.phoneNumberValidRules"
                                                              label="Cellular Phone Number">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-checkbox v-model="prescriptionAdd.optOutText"
                                                            label="Opt out from texts">
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="address in newPatientAddresses"
                                               :key="address.entityAddressId">
                                            <v-col>
                                                <v-row>
                                                    <v-col md="12">
                                                        <v-text-field prepend-icon="mdi-map-marker"
                                                                      ref="autocomplete"
                                                                      label="Search New Address"
                                                                      placeholder=""
                                                                      type="search">
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.addressLine1"
                                                              label="Address Line 1"
                                                              :rules="$data.addressLine1Rules"
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.addressLine2"
                                                              label="Address Line 2"
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.city"
                                                              label="City"
                                                              :rules="$data.cityRules"
                                                              prepend-icon="mdi-map-marker"></v-text-field>
                                            </v-col>
                                            <v-col md="6">
                                                <v-select :items="$data.states"
                                                          :rules="$data.stateRules"
                                                          item-text="description"
                                                          item-value="stateId"
                                                          v-model="selectedState"
                                                          @input="handleStateSelection"
                                                          return-object
                                                          label="State"
                                                          prepend-icon="mdi-map-marker">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <v-text-field v-model="newPatientAddress.zipCode"
                                                              label="Zip Code"
                                                              prepend-icon="mdi-map-marker"
                                                              v-mask="'#####'"
                                                              :rules="$data.zipCodeRules"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="patientDateOfBirtheMenu"
                                                        v-model="patientDateOfBirtheMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="prescriptionAdd.dateOfBirth"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="birthDateFormatted"
                                                                      label="Birth Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="prescriptionAdd.dateOfBirth"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.patientDateOfBirtheMenu.save(prescriptionAdd.dateOfBirth)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select v-model="languagePreference"
                                                          label="Language Preference"
                                                          :items="languagePreferenceItems"
                                                          item-text="name"
                                                          item-value="languagePreferenceId"
                                                          @change="languagePreferenceOtherCheck(languagePreference)"
                                                          return-object
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6" v-if="languagePreferenceOther">
                                                <v-text-field v-model="prescriptionAdd.otherLanguagePreference"
                                                              label="Other Languange"
                                                              :counter="50">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6" v-if="lineOfBusinessExist">
                                                <v-select v-model="lineofBusiness"
                                                          label="Line of Business"
                                                          :items="lineOfBusinessItems"
                                                          item-text="name"
                                                          item-value="lineOfBusinessId"
                                                          @change="lineofBusinessSelected(lineofBusiness)"
                                                          return-object
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="sitesExist">
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showSiteDialog" v-if="prescriptionAdd.siteId == null">Add Site</v-btn>
                                                <v-btn color="secondary" @click="showSiteDialog" v-else>Change Site</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="site.name"
                                                              label="Site Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-btn color="secondary" @click="showProviderDialog" v-if="prescriptionAdd.providerId == null">Add Provider</v-btn>
                                                <v-btn color="secondary" @click="showProviderDialog" v-else>Change Provider</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="provider.name"
                                                              label="Provider Name"
                                                              readonly>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.legacyNumber"
                                                              label="Rx Number">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="prescriptionAdd.fileStoreObjectName"
                                                              prepend-icon="mdi-image"
                                                              label="Rx Google Drive URL link">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="prescriptionAdd.note"
                                                            outlined
                                                            label="Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="isPreQualified">
                                            <v-col cols="12">
                                                <v-textarea v-model="prescriptionAdd.shippingNote"
                                                            outlined
                                                            label="Shipping Notes">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu ref="prescriptionDateMenu"
                                                        v-model="prescriptionDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="prescriptionAdd.prescriptionDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="prescriptionDateFormatted"
                                                                      label="Prescription Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="prescriptionAdd.prescriptionDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.prescriptionDateMenu.save(prescriptionAdd.prescriptionDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="receivedDateMenu"
                                                        v-model="receivedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="prescriptionAdd.received"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="receivedDateFormatted"
                                                                      label="Received Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="prescriptionAdd.received"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.receivedDateMenu.save(prescriptionAdd.received)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.onFulfillmentHold"
                                                          label="On Fulfillment Hold"
                                                          :items="booleanItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.initialDeliveryMethod"
                                                          label="Initial Delivery Method"
                                                          :items="deliveryMethodItems"
                                                          @changed="checkPreQualified"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="prescriptionAdd.trainingTypeDescription"
                                                          label="Training Type Description"
                                                          :items="trainingTypeItems"
                                                          outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                                <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                    <ul v-for="error in errors" :key="error">
                                        <li>{{error}}</li>
                                    </ul>
                                </v-row>
                                <v-row>
                                    <v-card-actions>
                                        <v-btn color="primary"
                                               dark
                                               :disabled="loading"
                                               @click="checkPrescriptionForm">
                                            Add
                                        </v-btn>
                                        <v-btn color="primary"
                                               dark
                                               text
                                               @click="closeAddPrescriptionDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-data-table :headers="headers"
                          :items="prescriptions"
                          sort-by="PrescriptionDate"
                          sort-desc
                          class="elevation-1"
                          :server-items-length="totalPrescriptions"
                          :options.sync="options"
                          :loading="loading"
                          :search="search"
                          :footer-props="{'items-per-page-options': rowsPerPage}">
                <template #item.prescription_details="{ item }">
                    {{ item.legacyId }}
                    <v-icon medium class="mr-2" @click="viewPrescriptionDetails(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #item.full_name="{ item }">
                    {{ item.patientFirstName }} {{ item.patientLastName }}
                    <v-icon medium class="mr-2" @click="viewPatient(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #[`item.isRenewalPatient`]="{ item }">
                    {{ item.isRenewalPatient==true?'Yes':'No' }}
                </template>
                <template v-slot:top>
                    <v-dialog v-model="dialog" max-width="750px" v-if="dialog">
                        <v-card>
                            <v-card-title class="headline primary">
                                {{ formTitle }}
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.name"
                                                          label="Name"
                                                          solo></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <v-textarea outlined
                                                        name="note"
                                                        label="Note"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogPrescription" v-if="dialogPrescription"
                              fullscreen
                              hide-overlay
                              transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="dialogPrescription = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                    Prescription - {{ selectedPrescriptionValues.legacyId }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="dialogPrescription = false">
                                        Close
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <PrescriptionDetails :patientFirstName="selectedPrescriptionValues.patientFirstName"
                                                 :patientLastName="selectedPrescriptionValues.patientLastName"
                                                 :prescribingPhysicianName="selectedPrescriptionValues.prescribingPhysicianName"
                                                 :prescriptionId="selectedPrescriptionValues.prescriptionId"
                                                 :prescriptionDate="formDateFormat(selectedPrescriptionValues.prescriptionDate)"
                                                 :legacyId="selectedPrescriptionValues.legacyId"
                                                 :created="formDateFormat(selectedPrescriptionValues.created)"
                                                 :updatedByUserId="selectedPrescriptionValues.updatedByUserId"
                                                 :updatedByUserName="selectedPrescriptionValues.updatedByUserName"
                                                 :received="formDateFormat(selectedPrescriptionValues.received)"
                                                 :voidDate="formDateFormat(selectedPrescriptionValues.voidDate)"
                                                 :voidedReasonId="selectedPrescriptionValues.voidedReasonId"
                                                 :voidNote="selectedPrescriptionValues.voidNote"
                                                 :trained="formDateFormat(selectedPrescriptionValues.trained)"
                                                 :delivered="formDateFormat(selectedPrescriptionValues.delivered)"
                                                 :legacyNumber="selectedPrescriptionValues.legacyNumber"
                                                 :patientId="selectedPrescriptionValues.patientId"
                                                 :fileStoreObjectName="selectedPrescriptionValues.fileStoreObjectName"
                                                 :isRenewalPatient="selectedPrescriptionValues.isRenewalPatient"
                                                 :healthPlanMemberId="selectedPrescriptionValues.healthPlanMemberId"
                                                 :providerId="selectedPrescriptionValues.providerId"
                                                 :processed="formDateFormat(selectedPrescriptionValues.processed)"
                                                 :assigned="formDateFormat(selectedPrescriptionValues.assigned)"
                                                 :note="selectedPrescriptionValues.note"
                                                 :assignedNote="selectedPrescriptionValues.assignedNote"
                                                 :trainedNote="selectedPrescriptionValues.trainedNote"
                                                 :isShipmentRequested="selectedPrescriptionValues.isShipmentRequested"
                                                 :onFulfillmentHold="selectedPrescriptionValues.onFulfillmentHold"
                                                 :initialDeliveryMethod="selectedPrescriptionValues.initialDeliveryMethod"
                                                 :isDeleted="selectedPrescriptionValues.isDeleted"
                                                 :trainingTypeDescription="selectedPrescriptionValues.trainingTypeDescription"
                                                 :lineOfBusinessName="selectedPrescriptionValues.lineOfBusinessName" />
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogPatient" v-if="dialogPatient"
                              fullscreen
                              hide-overlay
                              ransition="dialog-bottom-transition"
                              persistent :retain-focus="false">
                        <v-card>
                            <div style="background: #123559" v-if="false">
                                <v-btn icon dark @click="dialogPatient = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn class="float-right" dark text @click="dialogPatient = false"> Close</v-btn>
                            </div>
                            <v-toolbar dark color="primary" v-if="false">
                                <v-btn icon dark @click="closePatientDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8" @click="copyToClipboard(patientName)" v-on="on" v-bind="attrs">
                                            {{patientName}}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4"
                                                         @click="copyToClipboard(patientDesignation)"
                                                         v-on="on"
                                                         v-bind="attrs">
                                            {{patientDesignation}}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4">
                                    {{customerName}}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="closePatientDialog"> Close</v-btn>
                                </v-toolbar-items>
                                <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
                                    Copied "{{clipboardText}}" to clipboard

                                    <template v-slot:action="{ attrs }">
                                        <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
                                            Close
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                            <PatientDetails :patientId="patientDialogValues.patientId"
                                            :customerId="patientDialogValues.customerId"
                                            :customer-name="patientDialogValues.customerName"
                                            :key="componentKey"
                                            v-on:closed="closePatientDialog" />
                        </v-card>
                    </v-dialog>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon style="z-index: 2" class="mt-1">mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list-item-group v-show="item.voidDate === null || item.voidIsDeleted" elevation="2" style="position: relative; z-index: 3; background-color: white">
                            <v-list-item v-for="(action, index) in actionsVoid" :key="index" @click="actionSelected(item, action)">
                                <v-list-item-title>{{ action }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                        <v-list-item-group v-show="item.voidDate !== null && !item.voidIsDeleted" elevation="2" style="position: relative; z-index: 3; background-color: white">
                            <v-list-item v-for="(action, index) in actionsUnVoid" :key="index" @click="actionSelected(item, action)">
                                <v-list-item-title>{{ action }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-menu>
                </template>
                <template #[`item.patientName`]="{ item }">
                    {{ item.patientFirstName }} {{ item.patientLastName }}
                    <v-icon medium class="mr-2" @click="viewPatient(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #[`item.prescriptionDate`]="{ item }">
                    {{ item.prescriptionDate | formatDate}}
                </template>
                <template #[`item.voidedDate`]="{ item }">
                    <div v-show="!item.voidIsDeleted">
                        {{ item.voidDate | formatDate }}
                    </div>
                </template>
            </v-data-table>
            <!--Site Dialog-->
            <v-dialog v-model="dialogSite" v-if="dialogSite"
                      max-width="850px"
                      @click:outside="closeSiteDialog">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeSiteDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Search For Site
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="closeSiteDialog">Close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <form>
                            <v-row>
                                <v-col cols="12">
                                    <siteSearch v-on:site-selected="searchSites" :isReadonly="false"
                                                :customerId="customerId" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-card-actions>
                                    <v-btn color="primary"
                                           dark
                                           text
                                           @click="closeSiteDialog">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!--Provider Dialog-->
            <v-row>
                <v-dialog v-model="dialogProvider" v-if="dialogProvider"
                          max-width="850px"
                          @click:outside="closeProviderDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeProviderDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Search For or Add Provider
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeProviderDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <v-col cols="12">
                                        <providerSearch v-on:provider-selected="searchProviders"
                                                        :customerId="this.customerId" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <OrderProviderAdd mode="Add" @saved="providerSelected" :entity-providers="providers" :customer-id="customerId" @closed="closeProviderDialog"></OrderProviderAdd>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Void Reason Dialog-->
            <v-row>
                <v-dialog v-model="dialogVoidOrderView" v-if="dialogVoidOrderView"
                          @click:outside="closeVoidOrderDialog"
                          max-width="850px">

                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeVoidOrderDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Void Prescription Details
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeVoidOrderDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <form>
                                <v-row>
                                    <VoidReason @saved="saveVoid" 
                                                :order-id="selectedVoidOrderValues.orderId" 
                                                :customer-id="selectedVoidOrderValues.customerId" 
                                                :patient-first-name="selectedVoidOrderPatientValues.patientFirstName"
                                                :patient-last-name="selectedVoidOrderPatientValues.patientLastName"
                                                @closed="closeVoidOrderDialog"></VoidReason>
                                </v-row>
                            </form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--Edit Prescription Dialog-->
            <v-dialog v-model="dialogEditPrescription" v-if="dialogEditPrescription"
                      max-width="850px"
                      @click:outside="closeEditPrescriptionDialog">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeEditPrescriptionDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Edit Prescription Details
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="closeEditPrescriptionDialog">Close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <v-container>
                            <form>
                                <v-row>
                                    <v-col cols="6">
                                        <div class="text-h6"><strong>Patient:</strong> {{ patientDialogValues.patientFirstName }} {{ patientDialogValues.patientLastName }}</div>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="editPrescriptionItem.healthPlanMemberId"
                                                      label="Health Plan Member Id">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <div class="text-h6"><strong>RxId:</strong> {{ editPrescriptionItem.legacyId }}</div>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="editPrescriptionItem.prescribingPhysicianName"
                                                      label="Prescribing MD"
                                                      readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn color="secondary" @click="showProviderDialog" v-if="editPrescriptionItem.providerId == null">Add Provider</v-btn>
                                        <v-btn color="secondary" @click="showProviderDialog" v-else>Change Provider</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" v-if="lineOfBusinessExist">
                                                <v-select v-model="editPrescriptionItem.lineOfBusinessId"
                                                          label="Line of Business"
                                                          :items="lineOfBusinessItems"
                                                          item-text="name"
                                                          item-value="lineOfBusinessId"
                                                          outlined>
                                                </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-menu ref="editPrescriptionDateMenu"
                                                v-model="editPrescriptionDateMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="editPrescriptionItem.prescriptionDate"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="editPrescriptionDateFormatted"
                                                              label="Prescription Date"
                                                              prepend-icon="mdi-calendar"
                                                              v-bind="attrs"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="editPrescriptionItem.prescriptionDate"
                                                           no-title
                                                           scrollable
                                                           @input="$refs.editPrescriptionDateMenu.save(editPrescriptionItem.prescriptionDate)">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-menu ref="editReceivedDateMenu"
                                                v-model="editReceivedDateMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="editPrescriptionItem.received"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="editReceivedDateFormatted"
                                                              label="Received Date"
                                                              prepend-icon="mdi-calendar"
                                                              v-bind="attrs"
                                                              v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="editPrescriptionItem.received"
                                                           no-title
                                                           scrollable
                                                           @input="$refs.editReceivedDateMenu.save(editPrescriptionItem.received)">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select v-model="editPrescriptionItem.isRenewalPatient"
                                                  label="Renewal Patient"
                                                  :items="booleanItems"
                                                  outlined>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea v-model="editPrescriptionItem.note"
                                                    outlined
                                                    label="Notes">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select v-model="editPrescriptionItem.isShipmentRequested"
                                                  label="Shipment Requested"
                                                  :items="booleanItems"
                                                  outlined>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select v-model="editPrescriptionItem.onFulfillmentHold"
                                                  label="On Fulfillment Hold"
                                                  :items="booleanItems"
                                                  outlined>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select v-model="editPrescriptionItem.initialDeliveryMethod"
                                                  label="Initial Delivery Method"
                                                  :items="deliveryMethodItems"
                                                  @changed="checkPreQualified"
                                                  outlined>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select v-model="editPrescriptionItem.trainingTypeDescription"
                                                  label="Training Type Description"
                                                  :items="trainingTypeItems"
                                                  outlined>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </form>
                            <v-row>
                                <v-card-actions>
                                    <v-btn color="primary"
                                           dark
                                           :disabled="loading"
                                           @click="saveEdit">
                                        Save Edit
                                    </v-btn>
                                    <v-btn color="primary"
                                           dark
                                           text
                                           @click="closeEditPrescriptionDialog">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
        <v-snackbar v-model="submitSnackbar" :timeout="12000" bottom>
            {{ submitSnackbarText }}
            <v-btn color="blue"
                   align="right"
                   justify="end"
                   class="float-right"
                   @click="submitSnackbar = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import Vue from "vue";
    import moment from 'moment';
    import VoidReason from "./VoidReason.vue";
    import PrescriptionDetails from "./PrescriptionDetails.vue";
    import PatientDetails from "@/components/PatientDetails.vue";
    import PatientService from "@/services/PatientService";
    import ProviderService from "@/services/ProviderService";
    import ProviderSearch from "@/components/ProviderSearch.vue";
    import OrderProviderAdd from "./OrderProviderAdd.vue";
    import SiteSearch from "@/components/SiteSearch.vue";
    import SiteService from "@/services/SiteService";
    import { GetPatientLookupValues } from "../../common/queries/patient-lookup-values.graphql";
    import OrderIntakeService from "@/services/OrderIntakeService";
    import OrdersPatientSearch from "./OrdersPatientSearch.vue";
    import CustomerPrescriptionsService from "@/services/CustomerPrescriptionsService"
    import PrescriptionService from "@/services/PrescriptionService"
    import CustomerMasterService from "@/services/CustomerMasterService"
    import { contactDetailsUtils } from "@/mixins/contactDetailsUtils";
    import ReferenceDataService from "@/services/ReferenceDataService";

    Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY')
        }
    });

    export default Vue.extend({
        name: "PrescriptionList",
        mixins: [contactDetailsUtils],
        components: {
            OrderProviderAdd,
            PatientDetails,
            OrdersPatientSearch,
            PrescriptionDetails,
            SiteSearch,
            ProviderSearch,
            VoidReason
        },
        data: () => ({
            loading: true,
            dialogVoidOrderView: false,
            selectedVoidOrderValues: {},
            selectedVoidOrderPatientValues: {},
            totalPrescriptions: 0,
            componentKey: 0,
            selectedState: null,
            customerId: null,
            orderId: null,
            customerObject: {},
            patientIdentifierType: null,
            options: {},
            providers: [],
            search: "",
            patientDateOfBirtheMenu: false,
            prescriptionDateMenu: false,
            receivedDateMenu: false,
            editReceivedDateMenu: false,
            editPrescriptionDateMenu: false,
            dialog: false,
            dialogPrescription: false,
            dialogPatient: false,
            dialogSite: false,
            dialogAddPrescription: false,
            dialogEditPrescription: false,
            dialogProvider: false,
            prescriptionSelected: false,
            showRenewPrescription: false,
            sitesExist: false,
            languagePreference: null,
            languagePreferenceOther: false,
            lineofBusiness: null,
            showNoPatientRecord: false,
            showTooSoonToRenew: false,
            isPreQualified: false,
            submitSnackbar: false,
            submitSnackbarText: "",
            site: {},
            customerItems: [],
            providerTypeItems: [],
            errors: [],
            providerModeOfContactItems: [],
            languagePreferenceItems: [],
            lineOfBusinessItems:[],
            lineOfBusinessExist: false,
            patientIdentifierItems: [
                {
                    text: "Health Plan Member ID",
                    value: "healthPlanMemberId"
                },
                {
                    text: "Medical Record Number (MRN)",
                    value: "MRN"
                }
            ],
            actionsVoid: ["Edit", "Delete", "Void"],
            actionsUnVoid: ["Delete", "UnVoid"],
            headers: [
                {
                    text: "Prescription Date",
                    align: "start",
                    value: "prescriptionDate",
                },
                { text: "Prescription ID", value: "prescription_details" },
                { text: "Customer", value: "customerName" },
                {
                    text: "Patient Name",
                    align: "start",
                    value: "full_name",
                },
                { text: "Prescribing Physician", value: "prescribingPhysicianName" },
                { text: "Renewal Patient", value: "isRenewalPatient" },
                { text: "Line Of Business", value: "lineOfBusinessName" },
                { text: "Voided Date", value: "voidedDate", sortable: false },
                { text: "Actions", value: "actions", sortable: false },
            ],
            prescriptions: [],
            editedIndex: -1,
            booleanItems: [
                { text: 'True', value: true },
                { text: 'False', value: false },
            ],
            trainingTypeItems: [
                { text: 'Telephonic', value: 'Telephonic' },
                { text: 'In Person Training', value: 'InPerson' },
            ],
            deliveryMethodItems: [
                { text: 'UPS', value: 'UPS' },
                { text: 'In Clinic', value: 'In Clinic' },
                { text: 'Pre-qualified', value: 'Pre-qualified' },
            ],
            editPrescriptionItem: {},
            selectedPrescriptionValues: {},
            prescriptionAdd: {
                onFulfillmentHold: false,
                initialDeliveryMethod: "UPS",
                trainingTypeDescription: "Telephonic"
            },
            editedItem: {
                prescriptionDate: "",
                prescriptionId: "",
                customerName: "",
                customerId: "",
                patientId: "",
                patientName: "",
                prescribingPhysicianName: "",
            },
            defaultItem: {
                prescriptionDate: "",
                prescriptionId: "",
                customerName: "",
                customerId: "",
                patientId: "",
                patientName: "",
                prescribingPhysicianName: "",
            },
            patientDialogValues: {
                patientFirstName: String,
                patientLastName: String,
                patientId: Number,
                customerId: Number,
                firstName: String,
                lastName: String,
                customerName: String,
                patientDesignation: String,
            },
            provider: {},
            newPatientAddress: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                stateId: null,
                zipCode: "",
                entityTypeId: 1,
            },
            newPatientAddresses: [
                {
                    entityAddressId: null,
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    stateId: null,
                    zipCode: "",
                }
            ],
            autocomplete: {},
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Prescription" : "Edit Prescription";
            },

            rowsPerPage() {
                return this.$rowsPerPage;
            },

            birthDateFormatted() {
                if (this.prescriptionAdd.dateOfBirth == null) {
                    return '';
                }
                let isoDateString = moment(this.prescriptionAdd.dateOfBirth).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            prescriptionDateFormatted() {
                if (this.prescriptionAdd.prescriptionDate == null) {
                    return '';
                }
                let isoDateString = moment(this.prescriptionAdd.prescriptionDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            receivedDateFormatted() {
                if (this.prescriptionAdd.received == null) {
                    return '';
                }
                let isoDateString = moment(this.prescriptionAdd.received).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            editPrescriptionDateFormatted() {
                if (this.editPrescriptionItem.prescriptionDate == null) {
                    return '';
                }
                let isoDateString = moment(this.editPrescriptionItem.prescriptionDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            editReceivedDateFormatted() {
                if (this.editPrescriptionItem.received == null) {
                    return '';
                }
                let isoDateString = moment(this.editPrescriptionItem.received).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },
        },

        async mounted() {
            this.initAutocomplete();
        },

        watch: {
            options: {
                handler() {
                    this.getPrescriptions();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            search: {
                handler(val) {
                    if (!val)
                        return;
                    this.fetchEntriesDebounced();
                },
                deep: true,
            }
        },

        methods: {
            searchCleared() {
                this.fetchEntriesDebounced();
            },

            forceRerender() {
                this.componentKey += 1;
            },

            formDateFormat(date) {
                if (date != null) {
                    return moment(new Date(date)).format('MM/DD/YYYY');
                }
            },

            initAutocomplete() {
                // Create the autocomplete object, restricting the search predictions to
                // geographical location types.
                if (this.$refs.autocomplete === undefined || this.$refs.autocomplete[0] === undefined) {
                    setTimeout(() => {
                        this.initAutocomplete();
                    }, 2000);
                } else {
                    this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete[0].$refs.input);
                    this.autocomplete.setFields(["address_component"]);
                    this.autocomplete.setComponentRestrictions({ "country": ["us"] });
                    this.autocomplete.addListener("place_changed", () => {
                        let place = this.autocomplete.getPlace();
                        this.fillInAddress(place);
                    });
                }
            },

            checkPrescriptionForm(e) {
                this.errors = [];

                if (!this.prescriptionAdd.isRenewalPatient) {
                    if (!this.prescriptionAdd.patientFirstName) {
                        this.errors.push('Patient first name is required');
                    }
                    if (!this.prescriptionAdd.patientLastName) {
                        this.errors.push('Patient last name is required');
                    }
                    if (!this.prescriptionAdd.dateOfBirth) {
                        this.errors.push('Patient date of birth is required');
                    }
                    if (!this.newPatientAddress) {
                        this.errors.push('Patient address is required');
                    }
                    if (!this.prescriptionAdd.residencePhone && !this.prescriptionAdd.cellPhone) {
                        this.errors.push('Patient phone number is required');
                    }
                }

                if (!this.prescriptionAdd.providerId) {
                    this.errors.push('Provider is required');
                }
                if (this.patientIdentifierType == 'healthPlanMemberId' && !this.prescriptionAdd.healthPlanMemberId) {
                    this.errors.push('Health plan member ID is required');
                }
                if (this.patientIdentifierType == 'MRN' && !this.prescriptionAdd.medicalRecordNumber) {
                    this.errors.push('Medical Record Number is required');
                }
                if (this.sitesExist && !this.prescriptionAdd.siteId) {
                    this.errors.push('Prescription site is required');
                }
                if (!this.prescriptionAdd.prescriptionDate) {
                    this.errors.push('Prescription date is required');
                }
                if (!this.prescriptionAdd.fileStoreObjectName) {
                    this.errors.push('Prescription image upload is required');
                }
                if (this.prescriptionAdd.fileStoreObjectName && !this.prescriptionAdd.fileStoreObjectName.includes('https://')) {
                    this.errors.push('Prescription Image must be a Google drive link');
                }
                if (!this.prescriptionAdd.legacyNumber) {
                    this.errors.push('Prescription number is required');
                }
                if (!this.prescriptionAdd.received) {
                    this.errors.push('Prescription received date is required');
                }
                if (this.prescriptionAdd.onFulfillmentHold == null) {
                    this.errors.push('On fulfillment hold is required');
                }
                if (this.prescriptionAdd.initialDeliveryMethod == null) {
                    this.errors.push('Initial delivery method is required');
                }
                if (this.prescriptionAdd.trainingTypeDescription == null) {
                    this.errors.push('Training type is required');
                }
                if (this.newPatientAddress.addressLine1 == '') {
                    this.errors.push('Address Line 1 is required');
                }
                if (this.newPatientAddress.city == '') {
                    this.errors.push('City is required');
                }
                if (this.newPatientAddress.stateId == '') {
                    this.errors.push('State is required');
                }
                if (this.newPatientAddress.zipCode == '') {
                    this.errors.push('Zip Code is required');
                }
                if (this.lineOfBusinessExist && this.prescriptionAdd.lineOfBusinessId == null) {
                    this.errors.push('Line of Business is required');
                }
                if (!this.errors.length) {
                    this.submitPrescription();
                }

                e.preventDefault();
            },

            checkPreQualified(selection) {
                if (selection == 'Pre-qualified') {
                    this.isPreQualified = true;
                } else {
                    this.isPreQualified = false;
                }
            },

            handleStateSelection(selectedItem) {
                this.newPatientAddress.state = selectedItem.name;
                this.newPatientAddress.stateId = selectedItem.stateId;
            },

            languagePreferenceOtherCheck(language) {
                this.prescriptionAdd.languagePreferenceId = language.languagePreferenceId;

                if (language.name == 'Other') {
                    this.languagePreferenceOther = true;
                } else {
                    this.languagePreferenceOther = false;
                }
            },

            async customerSiteAndLineOfBusinessData(customer) {
                this.customerId = customer.customerId;
                if (customer.name == "KP Mid-Atlantic") {
                    this.patientIdentifierType = "MRN";
                } else {
                    this.patientIdentifierType = "healthPlanMemberId"
                }
                await this.getSitesByCustomerId(customer.customerId);

                await this.getLineOfBusinessByCustomerId(customer.customerId);

            },

            lineofBusinessSelected(lineOfBusiness){
                this.prescriptionAdd.lineOfBusinessId = lineOfBusiness.lineOfBusinessId;
            },

            fetchEntriesDebounced() {
                // cancel pending call
                clearTimeout(this._timerId);
                // delay new call 500ms
                this._timerId = setTimeout(() => {
                    this.getPrescriptions();
                }, 500);
            },

            fillInAddress(place) {
                // Get the place details from the autocomplete object.
                if (place != undefined) {
                    let components = {
                        street_number: {
                            result: "",
                            type: "short_name"
                        },
                        route: {
                            result: "",
                            type: "long_name"
                        },
                        locality: {
                            result: "",
                            type: "long_name"
                        },
                        administrative_area_level_1: {
                            result: "",
                            type: "short_name"
                        },
                        postal_code: {
                            result: "",
                            type: "short_name"
                        }
                    };

                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];
                        if (typeof components[addressType] !== "undefined") {
                            components[addressType].result = place.address_components[i][components[addressType].type];
                        }
                    }

                    this.newPatientAddress.addressLine1 =
                        (components["street_number"].result + " " + components["route"].result).trim();
                    this.newPatientAddress.city = components["locality"].result;
                    this.newPatientAddress.stateId =
                        this.stateIdFromShortName(components["administrative_area_level_1"].result);
                    this.newPatientAddress.state = components["administrative_area_level_1"].result;
                    this.newPatientAddress.zipCode = components["postal_code"].result;
                    this.newPatientAddress.addressLine2 = "";
                    this.selectedState = this.stateFromShortName(components["administrative_area_level_1"].result);
                }
            },

            async getPrescriptions() {
                this.loading = true;
                let pagedOptions = {
                    Search: this.search,
                    SortBy: this.options.sortBy != null ? this.options.sortBy[0] : null,
                    SortDesc: this.options.sortDesc != null ? this.options.sortDesc[0] : null,
                    ItemsPerPage: this.options.itemsPerPage,
                    Page: this.options.page
                };

                await new PrescriptionService().getAllPrescriptions(pagedOptions)
                    .then((value) => {
                        this.prescriptions = value.items;
                        this.totalPrescriptions = value.totalCount;
                        this.loading = false;
                    });
            },

            async getCustomers() {
                await new CustomerMasterService()
                    .getCommericalCustomer()
                    .then((value) => {
                        this.customerItems = value;
                    })
            },

            viewPrescriptionDetails: async function (item) {
                this.loading = true;
                await new CustomerPrescriptionsService()
                    .getPrescriptionDetails(item.customerId, item.prescriptionId)
                    .then((value) => {
                        this.selectedPrescriptionValues = Object.assign({}, value);
                        this.selectedPrescriptionValues.patientFirstName = item.patientFirstName;
                        this.selectedPrescriptionValues.patientLastName = item.patientLastName;
                        this.selectedPrescriptionValues.prescribingPhysicianName = item.prescribingPhysicianName;
                        this.selectedPrescriptionValues.prescriptionId = item.prescriptionId;
                        this.selectedPrescriptionValues.prescriptionDate = item.prescriptionDate;
                        this.selectedPrescriptionValues.updatedByUserName = item.updatedByUserName;
                        this.selectedPrescriptionValues.isRenewalPatient = item.isRenewalPatient;
                        this.selectedPrescriptionValues.voidDate = item.voidDate;
                        this.selectedPrescriptionValues.voidedReasonId = item.voidedReasonId;
                        this.selectedPrescriptionValues.voidNote = item.voidNote;
                        this.selectedPrescriptionValues.lineOfBusinessName = item.lineOfBusinessName;
                        this.customerId = item.customerId;

                        console.log("VoidId: " + this.selectedPrescriptionValues.voidedReasonId);
                        console.log("VoidId: " + item.voidedReasonId);
                        this.dialogPrescription = true;
                    })
                    .finally(() => (this.loading = false));
            },

            viewPatient(item) {
                this.patientDialogValues.patientId = item.patientId;
                this.patientDialogValues.customerId = item.customerId;
                this.patientDialogValues.firstName = item.patientFirstName;
                this.patientDialogValues.lastName = item.patientLastName;
                this.patientDialogValues.customerName = item.customerName;
                this.patientDialogValues.patientDesignation = item.patientDesignation;
                this.lookupPatient(item);
            },

            lookupPatient: async function (item) {
                this.loading = true;
                await new PatientService()
                    .get(item.customerId, item.patientId)
                    .then((value) => {
                        this.patient = value;
                        this.forceRerender();
                        this.dialogPatient = true;
                    })
                    .finally(() => (this.loading = false));
            },

            viewEditPrescription: async function (item) {
                this.loading = true;
                this.editPrescriptionItem = {};
                this.patientDialogValues = {};
                await new CustomerPrescriptionsService()
                    .getPrescriptionDetails(item.customerId, item.prescriptionId)
                    .then((value) => {
                        this.editPrescriptionItem = Object.assign({}, value);
                        this.patientDialogValues.patientFirstName = item.patientFirstName;
                        this.patientDialogValues.patientLastName = item.patientLastName;
                        this.editPrescriptionItem.customerId = item.customerId;
                        this.editPrescriptionItem.prescribingPhysicianName = item.prescribingPhysicianName;
                        this.editPrescriptionItem.prescriptionDate = value.prescriptionDate;
                        this.editPrescriptionItem.received = value.received;
                        this.customerId = item.customerId;
                        this.dialogEditPrescription = true;
                        if (value.lineOfBusinessId !==null) {
                            this.lineOfBusinessExist = true;
                            this.editPrescriptionItem.lineOfBusinessId = value.lineOfBusinessId;
                            this.getLineOfBusinessByCustomerId(item.customerId);
                        } else {
                            this.lineOfBusinessExist = false;
                        }
                    })
                    .finally(() => (this.loading = false));

                    if (this.editPrescriptionItem.providerId != null) {
                    await this.getProviderName(item.customerId, this.editPrescriptionItem.providerId);

                }
            },

            showDialogAddPrescription() {
                this.errors = [];
                this.dialogAddPrescription = true;
                this.getCustomers();
            },

            closeAddPrescriptionDialog() {
                this.dialogAddPrescription = false;
                this.showRenewPrescription = false;
                this.clearAddPrescriptionForm();
            },

            clearAddPrescriptionForm() {
                this.patientDialogValues = {};
                this.prescriptionAdd = {};
                this.prescriptionAdd = {
                    onFulfillmentHold: false,
                    initialDeliveryMethod: "UPS",
                    trainingTypeDescription: "Telephonic"
                };
                this.fileUpload = null;
                this.$refs.autocomplete = [];
                this.newPatientAddress = {
                    entityTypeId: 1,
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    stateId: null,    
                    zipCode: "",
                };
                this.newPatientAddresses = [
                    {
                        entityAddressId: null,
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        stateId: null,
                        zipCode: "",
                    },
                ],
                    this.autocomplete = {};
                this.provider = {};
                this.site = {};
                this.customerId = null;
                this.patientIdentifierType = null;
                this.customerObject = {};
                this.showNoPatientRecord = false;
                this.initAutocomplete();    
                this.selectedState = null;
                this.languagePreference = null;
                this.languagePreferenceOther = false;
                this.sitesExist = false;
                this.lineofBusiness = null;
                this.lineOfBusinessExist = false;
            },

            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },

            closeEditPrescriptionDialog() {
                this.dialogEditPrescription = false;
                this.provider = {};
                this.prescriptionAdd = {};
            },

            closePatientDialog() {
                this.dialogPatient = false;
                this.patientDialogValues = {};
                this.getPrescriptions();
            },

            closeProviderDialog() {
                this.dialogProvider = false;
            },

            closeVoidOrderDialog() {
                this.dialogVoidOrderView = false;
                this.selectedVoidOrderValues = {};
                this.selectedVoidOrderPatientValues = {};
            },

            async actionSelected(item, action) {
                if (action == 'Edit') {
                    await this.viewEditPrescription(item);
                } else if (action == 'Delete') {
                    await this.deletePrescription(item);
                } else if (action == 'Void') {
                    this.voidOrder(item);
                } else if (action == 'UnVoid') {
                    this.unVoidOrder(item);
                }
            },

            setProviderTypeValues(event) {
                this.provider.providerTypeName = event.name;
                this.provider.providerTypeId = event.providerTypeId;
            },

            showProviderDialog() {
                this.errors = [];
                this.dialogProvider = true;
            },

            showSiteDialog() {
                this.dialogSite = true;
            },

            closeSiteDialog() {
                this.dialogSite = false;
            },

            async deletePrescription(item) {
                let text = "Are you sure you want to delete " + item.legacyNumber + "?";
                if (confirm(text) == true) {
                    this.loading = true;
                    item.updatedByUserId = this.$store.state.achillesUser.userId;
                    await new CustomerPrescriptionsService()
                        .deleteCustomerPrescription(item)
                        .then(() => {
                            this.submitSnackbarText = "Deleted Successfully";
                            this.submitSnackbar = true;
                        })
                        .catch((err) => {
                            this.submitSnackbarText = "Something went wrong.";
                            this.submitSnackbar = true;
                            console.log(err);
                        });
                    this.getPrescriptions();
                    this.loading = false;
                }
            },

            prescriptionNumberIsInUse() {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            this.prescriptionAdd.customerId = this.customerId;
                            await new CustomerPrescriptionsService()
                                .getPrescriptionByLegacyNumber(this.prescriptionAdd.customerId, this.prescriptionAdd.legacyNumber)
                                .then((value) => {
                                    if (value.prescriptions.length !== 0 || value.prescriptions[0] !== undefined) {
                                        // Rx legacyNumber exist already
                                        return resolve(true);
                                    } else {
                                        return resolve(false);
                                    }
                                });
                        } catch (err) {
                            this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                            this.submitSnackbar = true;
                            console.log("Error checking for legacy Number uniqueness: " + err);
                        }
                    })();
                })
            },

            async saveVoid(value) {
                this.loading = true;
                this.selectedVoidOrderValues = Object.assign(this.selectedVoidOrderValues, value);
                this.selectedVoidOrderValues.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerPrescriptionsService()
                    .voidCustomerPrescription(this.selectedVoidOrderValues)
                    .then(() => {
                        this.submitSnackbarText = "Voided Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeVoidOrderDialog();
                this.getPrescriptions();
                this.loading = false;
            },

            voidOrder(item) {
                this.selectedVoidOrderPatientValues.patientFirstName = item.patientFirstName;
                this.selectedVoidOrderPatientValues.patientLastName = item.patientLastName;
                this.selectedVoidOrderValues.customerId = item.customerId;
                this.selectedVoidOrderValues.orderId = item.prescriptionId;
                this.selectedVoidOrderValues.patientId = item.patientId;
                this.dialogVoidOrderView = true;
            },

            async unVoidOrder(item) {
                let text = "Are you sure you want to unVoid " + item.legacyNumber + "?"
                if (confirm(text) == true) {
                    this.loading = true;
                    item.updatedByUserId = this.$store.state.achillesUser.userId;
                    item.orderId = item.prescriptionId;
                    await new CustomerPrescriptionsService()
                        .unVoidCustomerPrescription(item)
                        .then(() => {
                            this.submitSnackbarText = "UnVoided Successfully";
                            this.submitSnackbar = true;
                        })
                        .catch((err) => {
                            this.submitSnackbarText = "Something went wrong.";
                            this.submitSnackbar = true;
                            console.log(err);
                        });
                    this.getPrescriptions();
                    this.loading = false;
                }
            },

            async saveEdit() {
                this.loading = true;
                this.editPrescriptionItem.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerPrescriptionsService()
                    .editCustomerPrescription(this.editPrescriptionItem)
                    .then(() => {
                        this.submitSnackbarText = "Edited Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeEditPrescriptionDialog();
                this.loading = false;
                this.getPrescriptions();
            },

            async saveProvider() {
                this.loading = true;
                this.provider.updatedByUserId = this.$store.state.achillesUser.userId;
                this.provider.isDeleted = false;
                this.provider.customerId = this.customerId;
                this.provider.created = moment();
                this.provider.name = this.provider.firstName + " " + this.provider.lastName;
                await new ProviderService()
                    .postProvider(this.customerId, this.provider)
                    .then((value) => {
                        this.provider.providerId = value.data.providerId;
                        this.provider.name = value.data.name;
                        this.prescriptionAdd.providerId = value.data.providerId;
                        this.submitSnackbarText = "Added Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });
                this.closeProviderDialog();
                this.loading = false;
            },

            patientAlreadyExists() {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            this.prescriptionAdd.customerId = this.customerId;
                            await new CustomerPrescriptionsService()
                                .patientExistsPrescription(this.prescriptionAdd.customerId,
                                 this.prescriptionAdd.patientFirstName,
                                 this.prescriptionAdd.patientLastName,
                                 this.prescriptionAdd.dateOfBirth,
                                 this.prescriptionAdd.healthPlanMemberId,
                                 this.prescriptionAdd.medicalRecordNumber)
                                .then((value) => {
                                    if (value) {
                                        return resolve(true);
                                    } else {
                                        return resolve(false);
                                    }
                                });
                        } catch (err) {
                            this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                            this.submitSnackbar = true;
                        }
                    })();
                })
            },

            save() {
                if (this.editedIndex > -1) {
                    Object.assign(this.prescriptions[this.editedIndex], this.editedItem);
                } else {
                    this.prescriptions.push(this.editedItem);
                }
                this.close();
            },

            async submitPrescription() {
                this.loading = true;
                await this.savePrescription();
                this.loading = false;
            },

            async savePrescription() {
                this.prescriptionAdd = Object.assign(this.prescriptionAdd, this.newPatientAddress);
                this.prescriptionAdd.customerId = this.customerId;
                this.prescriptionAdd.updatedByUserId = this.$store.state.achillesUser.userId;

                if (await this.prescriptionNumberIsInUse()) {
                    this.submitSnackbarText = "A Prescription with this Rx Number already exists.";
                    this.submitSnackbar = true;
                    return false;
                }

                if (await this.patientAlreadyExists()) {
                    this.submitSnackbarText = "Patient already exists, do not create a new patient record.";
                    this.submitSnackbar = true;
                    return false;
                }

                await new OrderIntakeService()
                    .submitPrescription(this.prescriptionAdd)
                    .then(() => {
                        this.submitSnackbarText = "Added Successfully";
                        this.submitSnackbar = true;
                    })
                    .catch((err) => {
                        this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                        this.submitSnackbar = true;
                        console.log(err);
                    });

                this.getPrescriptions();
                this.closeAddPrescriptionDialog();
            },

            providerSelected(providerValue) {
                this.loading = true;
                if (providerValue.providerId != null) {
                    this.provider.name = providerValue.name;
                    this.prescriptionAdd.providerId = providerValue.providerId;
                    this.closeProviderDialog();
                }

                this.loading = false;
            },

            searchSites(siteValue) {
                this.loading = true;
                if (siteValue.siteId != null) {
                    this.site = Object.assign({}, siteValue);
                    this.prescriptionAdd.siteId = siteValue.siteId;
                    this.prescriptionAdd.siteName = this.site.name;
                    this.closeSiteDialog();
                }

                this.loading = false;
            },

            getSitesByCustomerId: async function (customerId) {
                this.loading = true;
                await new SiteService()
                    .getSites(customerId)
                    .then((value) => {
                        if (value.length !== 0 || value[0] !== undefined) {
                            this.sitesExist = true;
                        } else {
                            this.sitesExist = false;
                        }
                    })
                    .finally(() => (this.loading = false));
            },

            getLineOfBusinessByCustomerId: async function (customerId) {
                this.loading = true;
                await new ReferenceDataService()
                    .getLineOfBusiness(customerId)
                    .then((value) => {
                        if (value.length !== 0 || value[0] !== undefined) {
                            this.lineOfBusinessExist = true;
                            this.lineOfBusinessItems = value;
                        } else {
                            this.lineOfBusinessExist = false;
                        }
                    })
                    .finally(() => (this.loading = false));
            },

            getProviderName: async function (customerId, providerId) {
                this.loading = true;
                await new ProviderService()
                    .get(customerId, providerId)
                    .then((value) => {
                        this.provider.name = value.name;
                    })
                    .finally(() => (this.loading = false));
            },

            async searchProviders(providerValue) {
                this.loading = true;
                if (providerValue.providerId != null) {
                    this.provider = Object.assign({}, providerValue);
                    this.prescriptionAdd.providerId = providerValue.providerId;
                    this.editPrescriptionItem.providerId = providerValue.providerId;
                    this.editPrescriptionItem.prescribingPhysicianName = providerValue.name;
                    this.closeProviderDialog();
                }
                this.loading = false;
            },

            searchPrescription: async function (patientValue) {
                this.loading = true;
                this.showRenewPrescription = false;
                this.showTooSoonToRenew = false;
                this.showNoPatientRecord = false;
                await new CustomerPrescriptionsService()
                    .getPrescriptionByPatientId(patientValue.customerId, patientValue.patientId)
                    .then((value) => {
                        if (value.prescriptionId != null) {
                            if (moment().diff(moment(value.prescriptionDate), 'months') < 11) {
                                this.showTooSoonToRenew = true;
                            } else {
                                this.showNoPatientRecord = false;
                                this.prescriptionAdd.isRenewalPatient = true;
                                this.prescriptionAdd.patientId = patientValue.patientId;
                                this.prescriptionAdd.patientFirstName = patientValue.firstName;
                                this.prescriptionAdd.patientLastName = patientValue.lastName;
                                this.customerId = patientValue.customerId;
                                this.prescriptionAdd.providerId = value.providerId;
                                this.prescriptionAdd.healthPlanMemberId = value.healthPlanMemberId;
                                this.showRenewPrescription = true;
                            }
                        } else {
                            this.showNoPatientRecord = true;
                            this.showRenewPrescription = false;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
        },
        apollo: {
            patientLookupValues: {
                query: GetPatientLookupValues,
                variables() {
                    return {
                        customerId: this.customerId
                    };
                },
                update(data) {
                    this.languagePreferenceItems = data.customer.languagePreferences.data;
                }
            },
        }
    });
</script>
<style>
    .table-danger {
        background-color: #f5c6cb;
    }

    .table-warning {
        background-color: #ffeeba;
    }

    .table-success {
        background-color: #e1ffe3;
    }

    .text-success {
        color: #16bc2c;
    }

    .text-danger {
        color: #983a37;
    }
</style>